import React from 'react'
import './LetsTalk.css'

const LetsTalk = () => {
	return (
		<a href='#contact' type='button' className='btn btn-primary'>
			Let's talk
		</a>
	)
}

export default LetsTalk
